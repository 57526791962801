<template>
  <v-row
    align="center"
    justify="center"
    no-gutters
  >
    <v-dialog
      v-model="tuDialog"
      fullscreen
      hide-overlay
      persistent
      scrollable
      style="background-color: #fff"
     >

      <v-container fluid class="pa-0 ma-0">
        <v-row
          align="center"
          justify="center"
          no-gutters
          style="background-color: #fff"
        >
          <v-col cols="12" xs="12" sm="12" md="12" lg="12">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="pa-2 ma-2"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-toolbar
                  flat
                >
                  <h1 class="mx-1 my-2">
                    <v-icon class="mb-2">mdi-alert-circle-outline</v-icon>
                    이용약관
                  </h1>
                  <v-spacer></v-spacer>
                  <v-icon
                    large
                    @click="closePrivacy"
                  >mdi-close</v-icon>
                </v-toolbar>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-alert
                  text
                  color="grey lighten-1"
                  class="pa-5"
                >

                  <h3 class="text--primary text-h6 pb-2">제 1 조 (목적)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>
                        본 약관은 이용자가 <b>㈜현우엘앤씨가(이하 “회사”)</b>에서 제공하는 <b>온라인 관련 서비스(www.lawork.co.kr, 이하 “서비스”)</b>를 이용함에 있어서 이용자, 회사 간의 권리와 의무를 규정합니다.
                      </p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 2 조 (용어의 정의)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p class="font-weight-bold">본 약관의 용어는 다음과 같습니다.</p>
                      <p>가. 이용자 : 회사와 서비스 제공 계약을 체결한 자</p>
                      <p>나. 계정 : 이용자의 식별과 로그인 또는 본 서비스 및 다른 서비스 이용을 위해 필요한 이메일 주소(구글앱 접근 동의절차를 거친 구글계정)</p>
                      <p>다. 동의절차 : 회원가입, 로그인, 애플리케이션의 사용을 위해 구글에서 동의를 얻는 절차</p>
                      <p>라. 로워크 : 회사가 제공하는 서비스로서 이용자가 데이터를 공유하고, 체계적으로 업무를 수행할 수 있도록 하는 송무 관련 플랫폼</p>
                      <p>마. TO : 서비스를 공유하여 사용하도록 약정된 계정수</p>
                      <p>바. 조직 : 서비스를 사용하기 위해 필요한 그룹으로서, 1개의 계정은 1개의 조직에만 소속 가능</p>
                      <p>사. 이용자 등급 유형 :</p>
                      <p class="ml-4">- 관리자 계정 : 모든 정보의 소유권자로서 해당 조직을 생성시킨 자</p>
                      <p class="ml-4">- 관리대행자 계정 : 관리자 회원의 설정(위임)으로 관리자가 위탁한 정보에 접근할 수 있는 자</p>
                      <p class="ml-4">- 일반공유이용자 회원 : 관리자 또는 관리대행자 회원의 공유설정으로 공유된 정보에 접근할 수 있는 자</p>
                      <p class="ml-4">- 무료 회원 : 로워크에 공유되지 않은 자</p>
                      <p>아. 데이터 : 서비스에 등록된 개인정보, 텍스트, 파일 또는 기타 링크</p>
                      <p>자. 업무부서 : 조직 내에서 해당 업무를 공유하여 처리하는 그룹</p>
                      <p>카. 외부장치 : 이메일, 메신저, 파일스토리지 등 본 서비스와 연계되어 사용되어지는 외부 서비스</p>
                      <p>기타 본 약관에 정의되지 않은 용어는 개인정보보호정책의 정의를 따릅니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 3 조 (약관 효력의 발생)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>본 약관은 회사가 웹페이지에서 정한 소정의 회원가입절차에 따라 이용자가 회원가입을 함과 동시에 그 효력을 발합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 4 조 (저작권 등)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사가 서비스하는 자료는 회사가 그에 대한 권리를 보유하고 있는 자산으로서 저작권법 및 관련법령에 의해 보호받고 있습니다.</p>
                      <p>② 회사의 서비스 내용을 인용하거나 비영리적 목적으로 사용하실 경우에도 계약당사자와 회사와의 서면 계약을 통하여 회사의 출처를 밝힌 후 사용할 수 있습니다.<br>
                      <span class="ml-4"></span>허가 없이 위조, 변조, 복사, 양도, 배포, 출판, 전시, 판매하거나 상품제작, 인터넷 및 데이터베이스를 비롯한 각종 정보서비스 등에 사용하는 것을 금지합니다.</p>
                      <p>③ 회사의 서비스를 별도의 서면 계약에 의하여 이용하는 경우에도 계약 당사자 이외의 제3자에 대한 서비스 제공은 별도의 구체적인 언급이 없는 한 불가하며, 이에 대한 손해의 배상책임은 계약당사자가 집니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 5 조 (이용계약의 성립)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용계약은 이용희망자가 웹페이지상의 회원가입절차에 따라 이용신청을 하고, 회사가 회원가입을 승인함으로써 성립됩니다.</p>
                      <p>② 이용계약은 ID단위로 체결합니다.</p>
                      <p>③ 서비스 이용자와 이용요금 납입자가 상이한 경우, 회사는 이를 확인하기 위해 제증명을 요구할 수 있습니다.</p>
                      <p>④ 외부장치의 이용계약은 회사와의 계약이 아니라 해당 외부장치 서비스자와의 계약이며, 해당 서비스의 동의절차를 거친 경우, 그 계약이 체결된 것으로 봅니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 6 조 (이용 신청)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 본 서비스를 사용하려면 조직생성, 계정생성 및 장치연결을 요청해야 합니다.</p>
                      <p>② 조직 생성을 요청하고, 장치를 연결하면 본 약관에 동의하는 것으로 간주합니다.</p>
                      <p>③ 모든 회원은 반드시 본인의 정보를 제공하여야만 하며, 이를 위반시 해당 사용자는 일체의 권리를 주장할 수 없습니다.</p>
                      <p>④ 다음 각 호의 경우 조직 및 계정 생성 요청을 승인하지 않을 수 있습니다.</p>
                      <p class="ml-4">가. 반복적으로 조직 또는 계정을 생성하여 회사가 이를 삭제한 경우</p>
                      <p class="ml-4">나. 타인의 명의를 도용하여 조직 또는 계정을 만들려고 하거나 만든 경우</p>
                      <p class="ml-4">다. 조직생성을 위한 필수 정보를 허우로 제공한 경우</p>
                      <p class="ml-4">라. 로워크의 로직 및 기술 도용을 하기 위해 조직 및 계정 생성을 한 경우</p>
                      <p class="ml-4">마. 승인하지 않거나 삭제하는 것이 관련 법적 근거가 있거나 정당한 사유가 있어 소명할 수 있는 경우 ⑤ 전 항에 의한 경우 계정, 조직 및 등록된 일체의 정보를 사전예고없이 삭제될 수 있으며, 관계 법령에 따라 처벌될 수 있습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 7 조 (ID, 비밀번호 등의 관리)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사는 기본약관의 주요내용을 서비스에 게시하고, 이용자의 동의를 받아 가입처리를 합니다.</p>
                      <p class="error--text">② 관리자 계정은 절대로 변경, 수정 할 수 없습니다. 즉, 이는 현 조직의 폐쇄를 의미할 뿐입니다.</p>
                      <p class="error--text">③ 로워크의 로그인은 구글의 여러 편의 기능을 사용하기 위한 싱글사인온(SSO) 방식으로 연계됩니다.<br>
                      <span class=ml-4></span>그러므로, 로워크의 로그아웃이 곧 구글의 로그아웃을 의미하는 것이 아니어서, 반드시 별도의 구글 계정 로그아웃을 실행해야 합니다.<br>
                      <span class=ml-4></span>공공장소에서 사용 후 이를 등한시하여 유출된 것에 대해 회사는 책임을 지지 않습니다.</p>
                      <p>④ 공공장소에서의 이용이 많은 회원은 전 항을 방지하기 위해 구글의 보안단계를 상향 조정하기를 권합니다.</p>
                      <p>⑤ 비밀번호는 로워크에 저장되지 않으므로, 그 변경은 구글에서 가능합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 8 조 (서비스 내용)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사가 제공하는 서비스의 종류는 다음 각 호로 합니다.</p>
                      <p class="ml-4">가. 회사가 직접 제공하는 콘텐츠 및 송무관리 솔루션</p>
                      <p class="ml-4">나. 가 호에 부가되는 컨텐츠</p>
                      <p class="ml-4">다. 업무제휴를 통한 외부장치를 활용하는 제3자의 간접제공 콘텐츠 서비스 (파일스토리지, 이메일 등)</p>
                      <p>② 보다 신속하고 정확한 정보를 제공하기 위해 제공되는 정보의 내용은 자체 혹은 제3자를 통하여 정기적으로 업데이트 됩니다.</p>
                      <p>③ 고객에게 필요한 주요 서비스의 변경사항은 서비스의 공지채널을 통해 공지합니다.</p>
                      <p>④ 회사가 제공하는 구체적인 서비스의 내용은 온라인상의 “서비스이용안내”와 동일합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 9 조 (서비스 내용의 변경)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>서비스 내용이 추가, 변경 또는 삭제되는 경우에 회사는 이를 온라인의 공지채널을 이용하여 이를 공지합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 10 조 (서비스 이용)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 서비스 이용은 회사의 서비스 사용승낙 직후부터 가능합니다.</p>
                      <p>② 유료서비스의 경우에는 회사가 요금납입을 확인한 직후부터 사용가능합니다.<br>
                      <span class="ml-4"></span>단, 외부장치를 활용하는 제3자의 간접제공 콘텐츠 서비스 중 회원이 직접 결제해야 하는 서비스의 경우에는 그 요금납입이 이루어진 경우에만 사용가능합니다.</p>
                      <p>③ 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간을 원칙으로 합니다.<br>
                      <span class="ml-4"></span>단, 서비스 설비의 정기점검 등 회사가 정한 날이나 시간 또는 부득이한 사정에 의한 경우는 그러하지 아니합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 11 조 (서비스 이용의 제한 및 중지)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>회사는 다음 각 호에 해당하는 사유가 발생한 경우에는 사용자의 서비스 이용을 제한하거나 중지할 수 있습니다.</p>
                      <p>가. 사용자가 회사의 서비스의 운영을 고의 또는 과실로 방해하는 경우</p>
                      <p>나. 유료서비스 이용요금을 정한 기일 내에 납입하지 아니한 경우</p>
                      <p>다. 서비스용 설비 점검, 보수 또는 공사로 인한 부득이한 경우</p>
                      <p>라. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</p>
                      <p>마. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</p>
                      <p>바. 본 약관에서 정한 이용자의 의무를 위반한 경우</p>
                      <p>사. 기타 중대한 사유로 인하여 서비스 제공을 지속하는 것이 부적당하다고 판단되는 경우</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 12 조 (서비스 운영자의 의무)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사는 서비스 제공 및 보안과 관련된 설비를 지속적이고 안정적인 서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를 성실히 이행할 의무를 집니다.</p>
                      <p>② 회사는 서비스의 제공과 관련해서 알게 된 이용자의 개인정보를 본인의 승낙없이 제3자에게 누설, 배포하지 않습니다. 단, 다음 각 호에 해당하는 경우에는 회사가 그 책임을 면합니다.</p>
                      <p class="ml-4">가. 사용자가 정보제공자 또는 제3자에게 개별적으로 제공한 사용자정보가 정보제공자 또는 제3자에 의해 누설된 경우</p>
                      <p class="ml-4">나. 정보통신윤리위원회의 요청이 있는 경우</p>
                      <p class="ml-4">다. 관계기관으로부터 적법하고 정당하게 수사에 필요한 서류의 열람이나 제출을 서면으로 통지받아 이에 응할 경우</p>
                      <p class="ml-4">라. 회사가 정한 기간 동안 이용요금 등을 체납하여 신용정보사업자 또는 신용정보 집중기관에 제공하고자 하는 경우</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 13 조 (이용자의 의무)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용자는 다음 각 호의 1에 해당하는 행위를 하지 않아야 합니다.</p>
                      <p class="ml-4">가. 타인의 ID 및 비밀번호를 부정하게 사용하는 행위</p>
                      <p class="ml-4">나. 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 이용자의 비영리적인 이용이외의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위</p>
                      <p class="ml-4">다. 타인의 명예를 손상시키거나 불이익을 주는 행위</p>
                      <p class="ml-4">라. 공공질서 또는 미풍양속에 위배되는 내용의 정보, 문장, 도형, 음성 등을 유포하는 행위</p>
                      <p class="ml-4">마. 범죄적 행위와 결부된다고 판단되는 행위</p>
                      <p class="ml-4">바. 지적재산권을 침해하는 행위</p>
                      <p class="ml-4">사. 컴퓨터바이러스 감염 자료를 등록하는 행위</p>
                      <p class="ml-4">아. 상법 및 정보통신사업법 등 관계법령에 위배되는 행위</p>
                      <p class="ml-4">자. 기타 서비스의 정상적 운영, 유지 등을 방해하거나 지연시키는 행위</p>
                      <p>② 유료정보 이용자는 이용요금의 납입의무를 충실히 수행하여야 하며, 이용요금의 미납으로 인하여 발생되는 모든 책임은 이용자 및 이용요금 납입자에게 있습니다. 단, 회사에 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.</p>
                      <p>③ 이용자는 주소, 전화번호, 사업자등록번호 등 이용계약사항에 변동이 있을 경우에는 당해 내용을 지체 없이 회사에 고지하거나 서비스를 통해 변경한 후 변경내용을 확인하여야 합니다.<br>
                      <span class="ml-4"></span>이를 위반하여 고지 누락으로 발생한 불이익에 대해서 회사는 책임을 지지 않습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 14 조 (요금 체계 및 납입)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 서비스의 요금체계는 온라인상의 ‘이용안내’와 동일합니다.</p>
                      <p>② 요금 납입방법 및 요금에 대한 기타의 사항에 대해서는 회사의 공지 및 서비스별로 정하여진 바에 따릅니다. 단, 기능의 추가 등 특별한 사유로 그 내용을 변경하는 경우에는 회사가 정하여 서비스 이용안내에 게시하거나 별도로 공지하는 내용에 따릅니다.</p>
                      <p>③ 기본적 요금체계 외에 별도로 사용료가 부과되거나 회원이 직접 외부장치를 활용하는 제3자 서비스에 결제해야 하는 서비스의 경우 회사는 서비스 신청자가 용이하게 인지할 수 있는 형태로 사용자들에게 그 내용을 공시합니다.</p>
                      <p>④ 서비스내용의 변경이나 서비스이용범위의 변경은 온라인상의 공지사항 및 서비스 이용안내 등에 공지하고, 이러한 공지는 약관변경에 우선합니다. 단, 기존 이용자에게는 그 효력이 없는 것으로 합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 15 조 (유료 서비스 이용요금의 납입)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용요금은 서비스를 이용하기 전에 납입하는 것을 원칙으로 합니다.</p>
                      <p>② 외부장치를 활용하는 제3 서비스에 대한 내용은 해당 서비스의 정책에 따릅니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 16 조 (이용요금의 연체, 미납)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>회사가 이용요금 납입자에게 청구한 금액을 납입기일 내에 납입하지 않은 경우 서비스 이용을 제한 또는 중지 및 정보의 삭제를 할 수 있습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 17 조 (이용요금에 대한 이의신청)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사는 이용요금청구 등에 오류가 있는 경우에는 다음과 같이 조치합니다.</p>
                      <p class="ml-4">가. 원칙적으로 이용자가 과다 납입한 요금 등에 대하여는 그 금액을 반환합니다. 다만, 이용자가 동의할 경우 다음 달에 청구할 요금에서 해당금액만큼을 감하여 청구할 수 있습니다.</p>
                      <p class="ml-4">나. 회사의 업무진행상 누락된 청구액에 대해서는 익월에 합산하여 청구합니다.</p>
                      <p>② 이용요금에 관한 이의신청은 그 사유 발생을 안 날로부터 1월 이내, 그 사유가 발생한 날로부터 3월 이내에 하여야 합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 18 조 (양도 금지 및 지위승계)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용고객이 서비스를 받을 권리는 원칙적으로 이를 양도하거나 증여 등을 할 수 없으며, 질권의 목적으로도 사용할 수 없습니다.</p>
                      <p>② 다음 각 호에 해당하는 경우에는 전 항이 적용되지 않는 것으로 합니다.<br>
                      <span class="ml-4"></span>다만, 기존 로워크에 입력된 데이터 소유권에 대한 명의를 변경하기 위해서는 양수 희망 이용자는 회사가 정한 소정의 제증명서류의 제출과 필요경비의 제공을 부담해야 합니다.</p>
                      <p class="ml-4">가. 이용자의 사망으로 그 지위를 상속받은 때</p>
                      <p class="ml-4">나. 법인이 다른 법인을 흡수 합병하여 존속하는 법인이 그 사용권을 인수한 때</p>
                      <p class="ml-4">다. 둘 이상의 법인이 하나의 법인으로 합병하여 새로운 법인이 그 사용권을 인수한 때</p>
                      <p class="ml-4">라. 하나의 법인이 2개 이상의 법인으로 분할하는 경우로써 그 분할된 법인들 가운데 1인이 그 사용권을 인수한 때</p>
                      <p class="ml-4">마. 그밖에 ‘가’호 내지 ‘라’호와 유사한 사유로써 회사가 필요하다고 인정한 때</p>
                      <p>③ 전 항의 규정에 의하여 이용고객의 지위를 승계한 자는 그 사항을 지체없이 회사에 통보하여야 합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 19 조 (로워크에 등록된 데이터 소유권)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 로워크에 등록되어진 모든 데이터에 대한 소유권은 해당 관리자 계정에게 귀속됩니다.</p>
                      <p>② 회사는 데이터 소유권에 대한 어떠한 분쟁에 대해서도 참여하지 않습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 20 조 (회사의 로워크에 등록된 데이터 보관의무)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사는 이용계약 중의 일체의 데이터를 성실하게 보관할 의무를 부담합니다.</p>
                      <p>② 회사는 특별한 사정이 없는 한, 이용계약 만료일로부터 3일까지만 데이터 보관의무가 있습니다.<br>
                      <span class="ml-4"></span>그러므로, 회사는 이용계약 만료일로부터 3일이 경과한 후로부터는 데이터 보관에 대한 일체의 책임을 부담하지 않습니다.</p>
                      <p>③ 외부장치를 활용하는 제3자서비스에 보유된 정보에 대해서 회사는 책임을 지지 않습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 21 조 (이용계약의 해지)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용자가 서비스 이용계약을 해지하고자 하는 때에는 이용자 본인이 사전에 온라인, 방문, 전화 또는 팩스 등 회사가 인지할 수 있는 합리적인 방법을 통하여 회사에 해지신청을 하여야 합니다.</p>
                      <p class="error--text">② 계약의 주체로 외부장치를 활용하는 제3서비스에 가입한 한 경우에는 이용자는 해당 서비스에 직접 계약의 해지를 신청해야 하며, 회사는 아무런 책임이 없습니다.</p>
                      <p>③ 이용자가 다음 각 호에 해당하는 경우에는 회사는 사전 통보없이 계약을 해지할 수 있으며, 아래 사항으로 인해 회사의 서비스 운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임을 물을 수 있습니다.</p>
                      <p class="ml-4">가. 해킹, 컴퓨터 바이러스유포 등의 방법에 의하여 회사의 서비스 운영을 고의 또는 중대한 과실로 방해한 경우</p>
                      <p class="ml-4">나. 타인의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용한 경우</p>
                      <p class="ml-4">다. 타인의 계좌번호 및 신용카드번호를 도용한 경우</p>
                      <p class="ml-4">라. 회사의 사전 동의없이 서비스를 이용하여 얻은 정보를 영리적 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공한 경우</p>
                      <p class="ml-4">마. 회사의 저작권 등 권리를 침해한 경우</p>
                      <p class="ml-4">바. 회사의 서비스를 이용하여 타인의 명예 등 권리를 침해한 경우</p>
                      <p class="ml-4">사. 서비스 이용요금을 2개월 이상 납부하지 않은 경우</p>
                      <p class="ml-4">아. 선량한 풍속 기타 사회질서에 반하는 행위를 한 경우</p>
                      <p class="ml-4">자. 기타 회사의 서비스 사용자로서 부적합하다고 인정되는 경우</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 22 조 (계약해지의 효과 및 환불)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용계약의 해지시 회사는 최종요금납부 이후 이용한 서비스에 대한 이용료를 모두 청구하며, 이용료 납입자는 이를 모두 납입하여야 합니다.</p>
                      <p>② 전 항의 방법으로 이용계약해지 신청을 한 경우, 그 해지의 효과는 해지일 즉시 발생하며, 입금된 금액에서 사용일수 곱하기 정상가격(할인가격이 아님)을 제외한 나머지 금액이 환불됩니다. (별도-위약금10%)<br>
                      <span class="ml-4 error--text">단, 계약의 주체로 외부장치를 활용하는 제3자 서비스에 가입한 한 경우에는 해당 서비스가 책임질 문제일 뿐 회사와는 무관합니다.</span></p>
                      <p>③ 전 항의 경우, 회사는 더이상 등록된 데이터를 보관 및 제공할 의무가 없습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 23 조 (손해배상 등)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이용자가 서비스의 내용을 무단복제 또는 제3자에게 제공하거나, 인쇄물, CD-ROM, ON-LINE, 인터넷서비스, 기타 프로그램 등의 매체를 통하여 다른 목적에 이용할 때에는 이로 발생한 손해에 대하여 회사는 이용자에게 손해배상을 청구할 수 있습니다.</p>
                      <p>② 이용자가 회사의 저작권 등을 침해하는 경우 회사는 이용자에게 관련법규에 의하여 손해배상을 청구할 수 있습니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 24 조 (면책조항)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 회사는 천재지변 및 제3자의 고의적 서비스 방해 기타 불가항력적인 사유로 인해 서비스를 제공할 수 없는 경우에는 이로 인해 사용자가 사용을 할 수 없었음에 기해 발생하는 손해에 대한 책임을 면합니다.</p>
                      <p>② 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 면합니다.</p>
                      <p>③ 회사는 이용자가 서비스를 이용하여 얻은 정보 등으로 인해 입은 손해 등에 대하여는 책임을 면합니다. 이에는 회사가 회원의 데이터에 의해 자동산출하여 제공한 법정불변기한이 포함되며, 부작위에 의해 제공되지 않은 법정불변기한도 동일합니다.</p>
                      <p>④ 회사는 대법원, 헌법재판소, 검찰청 등 정보출처처 사이트의 기술상, 정책상 문제로 업데이트 기능의 불가능 또는 동 기관의 오기 입력, 입력사항의 임의 변경 등에 대해 책임을 면합니다.</p>
                      <p>⑤ 회사는 법령 등의 변경으로 인한 각종 계산프로그램 등의 단순 정보 제공 오류에 대해 책임을 면합니다.</p>
                      <p>⑥ 회사가 서비스로 제공한 사건관련정보는 법적효력이 없는 참고자료일 뿐이므로, 회사는 이용자가 그 자료만을 맹신한 결과로서 입게 된 손해 등에 대하여 책임을 면합니다.</p>
                      <p>⑦ 회사는 이용자가 서비스에 로그인 한 후 정상적으로 로그아웃하지 않아, 제3자에게 정보가 노출되거나 혹은 제3자가 해당 정보를 수정, 삭제하여 입은 손해에 대하여 책임을 면하며, 구글계정의 로그아웃이 이루어지지 않아 발생한 문제에 대해서도 동일합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 25 조 (약관의 변경)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>① 이 약관은 합리적인 사유가 발생할 경우 관련법령에 위배되지 않는 범위 안에서 개정될 수 있습니다.</p>
                      <p>② 개정된 약관은 온라인에서 공지함으로써 그 효력을 발생합니다. 단, 이용자의 권리 또는 의무 등 중요한 내용의 개정은 시행일로부터 최소 1주일 전에 공지합니다.</p>
                      <p>③ 기존 이용자가 변경 약관에 공지일 이후 14일 이내에 거부의사를 표할 수 있으며, 거부의 의사표시없이 동기간의 경과시에는 변경된 내용에 동의한 것으로 간주합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 26 조 (준용규정)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>이 약관에 명시되지 아니한 사항은 상법, 민법, 전자상거래등에서의소비자보호에관한법률 등 관련법령에 의하며, 법에 명시되지 않은 사항에 대해서는 상관습에 따릅니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">제 27 조 (관할법원)</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>서비스 이용요금 등 서비스 이용과 관련하여 발생한 분쟁에 대해서는 회사 소재지의 법원을 그 관할로 합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                  <h3 class="text--primary text-h6 pb-2">[부칙]</h3>
                  <div class="text--primary text-body-2">
                    <div class="ml-5">
                      <p>제 1 조 (시행일) : 본 약관은 2021년 4월 1일부터 시행합니다.</p>
                    </div>
                  </div>
                  <v-divider class="my-6"></v-divider>

                </v-alert>
              </v-col>
            </v-row>

            <!-- <v-divider class="my-3"></v-divider> -->

          </v-col>
        </v-row>
      </v-container>

    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      tuDialog: true
    }
  },

  mounted () {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    closePrivacy () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
/* .v-chip--small {
  height: 22px !important;
} */

/* .v-chip .v-chip__content {
  height: 22px;
  padding: 0 6px;
} */
</style>
